import React from 'react';
import Swiper from 'react-id-swiper';
import { Pagination, Navigation } from 'swiper/dist/js/swiper.esm';
import HeadlineAndText from 'Accessories/HeadlineAndText/HeadlineAndText';
import PropTypes from 'prop-types';

import { ReactComponent as CircleStriped } from "static/assets/brand/shapes/circle_striped.svg";
import { ReactComponent as SquareFilled } from "static/assets/brand/shapes/square-filled.svg";
import { ReactComponent as RectangleFilled } from "static/assets/brand/shapes/rectangle-filled.svg";
import { ReactComponent as RectangleStriped } from "static/assets/brand/shapes/rectangle_striped.svg";

import './SliderAttraction.scss';

const SliderAttraction = (props) => {

  const { children, classes, headline, subHeadline, link, linkText, distanceClasses } = props;

  const getRandomIntInclusive = (min, max) => {
    return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) + Math.ceil(min);
  }
  const layout = getRandomIntInclusive(1, 2);

  const sliderSettings = {
    modules: [Pagination, Navigation],
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpointsInverse: true,
    breakpoints: {
      768: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      }
    },
  };

  return (
    <div className={`section attraction-slider section-swiper container mt-lg-4 mb-8 ${distanceClasses}`}>
      {headline &&
        (
          <div className="row">
            <div className="col-12 col-md-10 col-lg-8 mx-auto">
              <HeadlineAndText headline={headline} text={subHeadline} link={link} linkText={linkText} isSubHeadline />
            </div>
          </div>
        )
      }
      <div className="row justify-content-center mt-2 swiper-row">
        {layout === 1 && (
          <div className="layout layout-1">
            <RectangleStriped className="shape-1" />
            <SquareFilled className="shape-2" />
          </div>
        )}
        {layout === 2 && (
          <div className="layout layout-2">
            <RectangleFilled className="shape-3" />
            <CircleStriped className="shape-4" />
          </div>
        )}
        <div className="col-12 p-0">
          <Swiper
            modules={[Pagination]}
            {...sliderSettings}
            containerClass={classes}
          >
            { children }
          </Swiper>
        </div>
      </div>
    </div>
  )

}

SliderAttraction.propTypes = {
  settings: PropTypes.object, // eslint-disable-line react/forbid-prop-types,
};

SliderAttraction.defaultProps = {
  settings: {
    grabCursor: true,
    slidesPerView: 'auto',
    loop: false,
  }
};

export default SliderAttraction